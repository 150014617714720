<template>
  <div class="h-5 w-full overflow-hidden rounded-md bg-primary flex group">
    <div
      :style="{ width: `${parseInt(value)}0%` }"
      class="h-full bg-green-600 ml-auto rounded-l-md group-hover:bg-green-500 text-center"
    >
      {{ value }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  setup() {},
};
</script>
