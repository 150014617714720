<template>
  <div class="relative z-10">
    <Listbox v-model="section">
      <ListboxLabel class="text-white text-sm">{{ label }}</ListboxLabel>
      <div class="relative mt-1">
        <ListboxButton
          class="relative inline-flex items-center justify-between text-sm px-2 w-full cursor-default rounded-md bg-white py-1 focus:outline-none"
        >
          <span class="block truncate">{{ selected.value }}</span>
          <span class="pointer-events-none">
            <i class="fa-solid fa-chevron-down" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-96 block min-w-full whitespace-nowrap overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="hasil in data"
              :key="hasil.value"
              :value="hasil"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-5 pr-5',
                ]"
              >
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block']">
                  {{ hasil.value }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center px-1 text-amber-600"
                >
                  <i class="fa-solid fa-check" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

const props = defineProps({
  label: String,
  data: Array,
  modelValue: String,
});

const section = computed({
  get: () => props.modelValue,
  set: (value) => defineEmits('update:modelValue', value),
});

// console.log(section.value);

// const handleChangeSelect = (e) => {
//   console.log(e.target.value);
// };

const selected = ref(props.data[0]);
// console.log(selected.value);
</script>
