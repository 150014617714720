export const numId = (val, slice) => {
  let idr = new Intl.NumberFormat('id-ID', {
    style: 'decimal',
  }).format(val);
  if (slice) {
    // idr = idr.slice(0, -3);
    // eslint-disable-next-line prefer-destructuring
    idr = idr.split(',')[0];
  }
  return idr;
};

export const idr = (val, slice) => {
  // eslint-disable-next-line no-shadow
  let idr = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(val);
  if (slice) {
    idr = idr.slice(0, -3);
  }
  return idr;
};

export const usd = (val, slice) => {
  // eslint-disable-next-line no-shadow
  let usd = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(val);
  if (slice) {
    usd = usd.slice(0, -3);
  }
  return usd;
};

export const numCustom = (val) => val.toString().split('.')[0];
