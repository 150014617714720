/* eslint-disable no-param-reassign */
/* eslint-disable newline-per-chained-call */
import { createApp } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import axios from 'axios';
import excel from 'vue-excel-export';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/styles/main.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

axios.interceptors.request.use((config) => {
  config.baseURL = process.env.VUE_APP_ROOT_API;
  return config;
});

const app = createApp(App).use(store).use(router).use(excel).use(VueApexCharts).use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA046t5sdQGuQcA_7y5RcwZYKtRWqnDcng',
  },
});

app.config.globalProperties.$filters = {
  numId(val, slice) {
    let idr = new Intl.NumberFormat('id-ID', {
      style: 'decimal',
    }).format(val);
    if (slice) {
      // idr = idr.slice(0, -3);
      // eslint-disable-next-line prefer-destructuring
      idr = idr.split(',')[0];
    }
    return idr;
  },
  idr(val, slice) {
    let idr = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(val);
    if (slice) {
      idr = idr.slice(0, -3);
    }
    return idr;
  },
  usd(val, slice) {
    let usd = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(val);
    if (slice) {
      usd = usd.slice(0, -3);
    }
    return usd;
  },
};

app.mount('#app');
