<template>
  <router-view />
</template>

<script>
import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
  setup() {
    const store = useStore();
    onBeforeMount(async () => {
      document.title = 'Sistem Informasi Harga dan Produk Komoditi Provinsi Jawa Tengah |  Sihati';
      moment.locale('id');
      await store.dispatch('kabupatenModule/actionMapsKab');
      // await store.dispatch('tablehargaModule/actionGetTableHarga');
      await store.dispatch('makroekonomiModule/actionGetMakroekonomi');
      await store.dispatch('dataModule/actionGetKomoditas');
      await store.dispatch('InflasiIndex/actionInflasijateng');
      await store.dispatch('InflasiIndex/actionInflasiDaerah');
      await store.dispatch('produksiModule/actionGetProduksi');
    });
  },
};
</script>

<style lang="scss"></style>
