<template>
  <div>
    <h3 class="text-center text-white text-xl mb-5">Inflasi Jawa Tengah</h3>
    <Card class="text-center text-white">
      <span>{{ nameInflasi }}</span>
    </Card>
    <div class="w-full space-y-3">
      <Accordion v-for="item in dataMakro" :key="item" :data="item" />
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion/index.vue';
import Card from '@/components/Card/index.vue';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

export default {
  components: {
    Accordion,
    Card,
  },
  setup() {
    const store = useStore();
    const dataMakro = computed(() => store.getters['InflasiIndex/getInflasiJateng']);
    const nameInflasi = computed(() => store.getters['InflasiIndex/getNameInflasi']);
    // console.log(dataMakro.value);
    onMounted(async () => {
      // await store.dispatch('InflasiIndex/actionInflasijateng');
    });
    const data = [
      {
        tahun: 2022,
        data: [
          {
            bulan: 'februari',
            value: 60,
          },
          {
            bulan: 'januari',
            value: 40,
          },
        ],
      },
      {
        tahun: 2023,
        data: [
          {
            bulan: 'februari',
            value: 60,
          },
          {
            bulan: 'januari',
            value: 40,
          },
        ],
      },
    ];

    return { data, dataMakro, nameInflasi };
  },
};
</script>
