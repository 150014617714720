<template>
  <div class="">
    <apexchart
      type="line"
      height="80"
      width="100"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <!-- {{ Object.values(data) }} -->
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    // console.log(this.$props.data);
    return {
      series: [
        {
          data: Object.values(this.$props.data),
        },
      ],
      chartOptions: {
        chart: {
          height: 100,
          type: 'line',
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        colors: ['#3878DE'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        markers: {
          size: 1,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: undefined,
          },
          x: {
            show: false,
          },
          y: {
            show: false,
            formatter: undefined,
            title: {
              formatter: () => '',
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  },
  // setup(props) {
  //   console.log(Object.values(props.data));
  // },
};
</script>
