// import getMaps from '@/utils/getMaps.json';
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    komoditas: [],
  },
  getters: {
    getDataKomoditas: (state) => state.komoditas,
    // eslint-disable-next-line eqeqeq
    getKomoditasById: (state) => (id) => state.komoditas.find((komoditi) => komoditi.ko_id == id),
  },
  mutations: {
    SET_DataKomoditas(state, value) {
      state.komoditas = value;
      // state.contractor_maintenances.push(...value)
    },
  },
  actions: {
    async actionGetKomoditas({ commit }) {
      await axios
        .get('api/harga/komoditas?type=')
        .then((response) => {
          // console.log(response.data.komoditas);
          commit('SET_DataKomoditas', response.data.komoditas);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(response.data);
    },
  },
};
