import { createStore } from 'vuex';
import Data from './modules/data';
import InflasiIndex from './modules/inflasi';
import perubahan from './modules/perubahan';
import Kabupaten from './modules/kabupaten';
import Makroekonomi from './modules/makroekonomi';
import TableHarga from './modules/tableharga';
import Produksi from './modules/produksi';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    dataModule: Data,
    InflasiIndex,
    perubahanModule: perubahan,
    kabupatenModule: Kabupaten,
    makroekonomiModule: Makroekonomi,
    tablehargaModule: TableHarga,
    produksiModule: Produksi,
  },
  strict: debug,
});
