import axios from 'axios';

export default {
  namespaced: true,
  state: {
    produksi: [],
  },
  getters: {
    getProduksi: (state) => state.produksi,
  },
  mutations: {
    SET_Produksi(state, value) {
      state.produksi = value;
      // state.contractor_maintenances.push(...value)
    },
  },
  actions: {
    async actionGetProduksi({ commit }) {
      await axios
        .get('api/harga/produksi?kabupaten=0')
        .then((response) => {
          // console.log(response.data.produksi);
          commit('SET_Produksi', response.data.produksi);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(response.data);
    },
  },
};
