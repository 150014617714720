import axios from 'axios';
// import moment from 'moment';
// import moment from 'moment';

export default {
  namespaced: true,
  state: {
    maps: [],
    allKab: [],
    nameKab: [],
    harga: [],
    all: [],
    avg: [],
    histogram: {
      data: [],
      category: [],
      colors: [],
    },
    mapdetail: [],
    komoditas: 'Komoditas',
    isLoading: false,
    isLoadingDetail: false,
    jenisHarga: null,
    komoditi: null,
    periode: null,
  },
  getters: {
    getMaps: (state) => state.maps,
    getAllKab: (state) => state.allKab,
    getNameKab: (state) => state.nameKab,
    getHarga: (state) => state.harga,
    getKomoditas: (state) => state.komoditas,
    getAll: (state) => state.all,
    getAvg: (state) => state.avg,
    getHistogram: (state) => state.histogram,
    getMapDetail: (state) => state.mapdetail,
  },
  mutations: {
    SET_Loading(state, value) {
      state.isLoading = value;
    },
    SET_LoadingDetail(state, value) {
      state.isLoadingDetail = value;
    },
    SET_Maps(state, value) {
      state.maps = value;
    },
    SET_AllKab(state, value) {
      state.allKab = value;
    },
    SET_Harga(state, value) {
      state.harga = value;
    },
    SET_Komoditas(state, value) {
      state.komoditas = value;
    },
    SET_All(state) {
      state.all = [];
      state.allKab.map((item) => {
        const data = state.harga[item.ka_id];
        return state.all.push({
          ...data,
          ka_name: item.ka_kabupaten,
          ka_svg: item.svg,
        });
      });
    },
    SET_Histogram(state) {
      state.histogram.data = [];
      state.histogram.category = [];
      state.histogram.colors = [];
      // eslint-disable-next-line array-callback-return
      state.all.map((item) => {
        // eslint-disable-next-line radix
        state.histogram.data.push(parseInt(item?.harga_int));
        state.histogram.category.push(item?.ka_name.split(' ')[1]);
        state.histogram.colors.push(item?.svg_color);
      });
      state.histogram.data.shift();
      state.histogram.category.shift();
      state.histogram.colors.shift();
    },
    SET_Avg(state) {
      state.avg = [];
      state.all.map((item) => state.avg.push(item?.harga_selisih_int));
      state.avg.shift();
    },
    SET_NameKab(state) {
      state.nameKab = [];
      state.all.map((item) => state.nameKab.push(item?.ka_name.split(' ')[1]));
      state.nameKab.shift();
    },
    SET_MapDetail(state, value) {
      state.mapdetail = value;
    },
    SET_JenisHarga(state, value) {
      state.jenisHarga = value;
    },
    SET_Komoditi(state, value) {
      state.komoditi = value;
    },
    SET_Periode(state, value) {
      state.periode = value;
    },

  },
  actions: {
    async actionMapsKab({ commit }) {
      commit('SET_Loading', true);
      await axios
        .get('api/harga/kabupaten?provinsi=1')
        .then((response) => {
          // console.log(response.data.kabupaten);
          commit('SET_Maps', response.data.kabupaten);
          commit('SET_AllKab', response.data.kabupaten);
          commit('SET_NameKab');
          // commit('SET_Histogram');
          commit('SET_Loading', false);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async actionHargaMap({ commit, dispatch }, value) {
      // console.log(value);
      const date = new Date();
      const today = new Date(date.setDate(date.getDate())).toISOString().slice(0, 10);

      // let today;
      // if (date.getDay() === 1) {
      //   today = new Date(date.setDate(date.getDate() - 2)).toISOString().slice(0, 10);
      // }
      // today = new Date(date.setDate(date.getDate() - 1)).toISOString().slice(0, 10);

      // console.log(today);
      // console.log(value?.date);

      commit('SET_Loading', true);
      commit('SET_Komoditi', value?.komoditi ?? 1);
      commit('SET_Periode', value?.periode ?? 'dtd');
      commit('SET_JenisHarga', value?.jenisharga ?? 0);
      await dispatch('actionMapsKab');
      await axios
        .get(`api/harga/map?komoditas=${value?.komoditi ?? 1}&tipe=${value?.jenisharga ?? 1}&tanggal=${value?.date ?? today}&periode=${value?.periode ?? 'dtd'}`)
        .then((response) => {
          // console.log(response.data.perubahan);
          commit('SET_Komoditas', response.data.perubahan.komoditas);
          commit('SET_Harga', response.data.perubahan.harga_result);
          commit('SET_All');
          commit('SET_Avg');
          commit('SET_Histogram');
          commit('SET_Loading', false);
        })
        .catch(() => {
          // console.log(error);
          commit('SET_Loading', false);
        })
        .finally(() => {
          commit('SET_Loading', false);
        });
    },
    async actionHargaMapDetail({ commit, state }, value) {
      // console.log(value);
      commit('SET_LoadingDetail', true);
      await axios
        .get(`api/harga/map_detail?kabupaten=${value.id_kab}&komoditas=${state.komoditi}&tanggal=${value.tanggal}&periode=${state.periode}&tipe=${state.jenisHarga}`)
        .then((response) => {
          // console.log(response.data.perubahan.harga_result[value]);
          commit('SET_MapDetail', response.data);
          // commit('SET_LoadingDetail', false);
        })
        .catch(() => {
          console.log();
          commit('SET_LoadingDetail', false);
        })
        .finally(() => {
          commit('SET_LoadingDetail', false);
        });
    },
  },
};
