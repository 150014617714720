<template>
  <div class="kontak">
    <h4 class="text-white text-center text-xl font-semibold mb-3">Kontak Kami</h4>
    <div class="grid lg:grid-cols-3 grid-cols-1 gap-4 text-white">
      <Card class="flex items-center">
        <div class="icon">
          <i class="fa-solid fa-phone"></i>
        </div>
        <span>(024) 8310246</span>
      </Card>
      <Card class="flex items-center">
        <div class="icon">
          <i class="fa-solid fa-envelope"></i>
        </div>
        <span>Email : tpidjateng@gmail.com</span>
      </Card>
      <Card class="flex items-center">
        <div class="icon">
          <i class="fa-solid fa-location-dot"></i>
        </div>
        <span class="text-sm">
          Sekretariat TPID Jawa Tengah Kantor Perwakilan Bank Indonesia Provinsi Jawa Tengah Jl.
          Imam Bardjo SH No. 4, Kota Semarang
        </span>
      </Card>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <!-- <div class="lg:col-span-8 col-span-12">
        <img src="@/assets/image/maps.png" alt="" />
      </div> -->
      <GMapMap :center="center" :zoom="18" class="lg:col-span-8 col-span-12" map-type-id="terrain">
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="false"
            :draggable="false"
            @click="center = m.position"
          />
        </GMapCluster>
      </GMapMap>
      <Card class="lg:col-span-4 col-span-12">
        <form @submit.prevent="handleSubmit">
          <div class="overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium text-white"
                    >Nama Lengkap</label
                  >
                  <input
                    type="text"
                    v-model="kontak.name"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    class="mt-1 text-white focus:ring-indigo-500 bg-primary focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-0 ring-0 rounded-md"
                  />
                </div>

                <div class="col-span-6">
                  <label for="email-address" class="block text-sm font-medium text-white"
                    >Email</label
                  >
                  <input
                    type="text"
                    v-model="kontak.email"
                    name="email-address"
                    id="email-address"
                    autocomplete="email"
                    class="mt-1 text-white focus:ring-indigo-500 bg-primary focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-0 ring-0 rounded-md"
                  />
                </div>
                <div class="col-span-6">
                  <label for="email-address" class="block text-sm font-medium text-white"
                    >Tulis Pesan</label
                  >
                  <textarea
                    id="pesan"
                    v-model="kontak.pesan"
                    name="pesan"
                    rows="3"
                    class="block w-full sm:text-sm rounded-md bg-primary border-0 ring-0 text-white"
                    placeholder="you@example.com"
                  />
                </div>
              </div>
            </div>
            <div class="px-4 py-3 text-center">
              <button
                type="submit"
                class="py-2 px-4 text-sm font-medium rounded-md text-white bg-indigo-600"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  </div>
</template>

<script setup>
import Card from '@/components/Card/index.vue';
import { reactive, ref } from 'vue';

const center = ref({ lat: -6.9946758, lng: 110.4226331 });
const markers = ref([
  {
    position: {
      lat: -6.9946758,
      lng: 110.4226331,
    },
  }, // Along list of clusters
]);

const kontak = reactive({
  name: '',
  email: '',
  pesan: '',
});

const handleSubmit = () => {
  console.log(kontak);
};
</script>

<style lang="scss" scoped>
.kontak {
  .icon {
    position: relative;
    background: rgba(28, 161, 247, 0.21);
    min-width: 48px;
    min-height: 48px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    i {
      color: rgba(28, 161, 247, 1);
    }
  }
}
</style>
