<template>
  <div class="box-map">
    <!-- {{ dataModal }} -->
    <svg
      id="getMap"
      version="1.1"
      data-id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="197 -87 543 325"
      style="enable-background: new 197 -87 543 325"
      xml:space="preserve"
    >
      <g
        v-show="!isLoading"
        v-for="data in dataAll"
        :key="data.kabupaten_id"
        @click="modalData(data)"
        class="btn-maps tooltipped"
        id="tooltipped"
        data-position="top"
        data-delay="50"
        :name-tooltip="data.kabupaten"
        :date-tooltip="data.tanggal"
        :harga-tooltip="changeValue(data.harga)"
        data-tooltip-id="88c1014f-b50a-64b2-49f6-b31c47acee80"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          stroke="#fff"
          :fill="data.svg_color"
          :d="data.ka_svg"
        />
      </g>
    </svg>
    <Modal :isModalprop="isModal" @closeBtn="isModal = false" :datas="dataModal" />
    <div class="loadingspinner" v-if="isLoading"></div>
    <div class="description"></div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/components/Modal/index.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const store = useStore();
    const dataAll = computed(() => store.getters['kabupatenModule/getAll']);
    const isLoading = computed(() => store.state.kabupatenModule.isLoading);
    const jenisHarga = computed(() => store.state.kabupatenModule.jenisHarga);
    const isModal = ref(false);

    const dataModal = ref(null);

    function modalData(value) {
      // console.log(value);
      const state = {
        id_kab: value.kabupaten_id,
        tanggal: value.tanggal,
      };
      dataModal.value = value;
      isModal.value = true;
      store.dispatch('kabupatenModule/actionHargaMapDetail', state);
    }

    const changeValue = (value) => {
      // console.log(value);
      let val;
      if (value) {
        // eslint-disable-next-line eqeqeq
        if (value != 'Rp.0') {
          // eslint-disable-next-line radix
          const num = parseInt(value);
          val = jenisHarga.value === 2 ? `${num} %` : value.replace('.', '').replace(',', '.');
        } else {
          val = 'N/A';
        }
      } else {
        val = jenisHarga.value === 2 ? '0 %' : 'N/A';
        // val = jenisHarga.value === 2 ? (val = 'NaN') : (val = 'NaN');
      }

      return val;
    };

    // console.log(dataModal.value);

    function tooltipOld() {
      let description = document.querySelector('.description');
      let btnMaps = document.querySelectorAll('.btn-maps');
      // eslint-disable-next-line array-callback-return
      btnMaps.forEach((item) => {
        item.addEventListener('mouseenter', () => {
          description.classList.add('active');
          description.innerHTML = `
          <p class="font-semibold">${item.getAttribute('name-tooltip')}</p>
          <p class="font-semibold">${item.getAttribute('harga-tooltip')}</p>
          <p>(${item.getAttribute('date-tooltip')})</p>
          `;
        });
        item.addEventListener('mouseout', () => {
          description.classList.remove('active');
        });
      });

      document.getElementById('getMap').addEventListener('mousemove', (e) => {
        // const x = (e.pageX / window.innerWidth).toFixed(2);
        // const y = (e.pageY / window.innerHeight).toFixed(2);
        // document.documentElement.style.setProperty('--x', x);
        // document.documentElement.style.setProperty('--y', y);
        // console.log(e.clientX);
        // description.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
        description.style.left = `${e.pageX - 200}px`;
        description.style.top = `${e.pageY - 460}px`;
        // description.style('left', e.pageX);
      });
    }

    // function initTooltip() {
    //   const tooltips = Array.from(document.querySelectorAll('[data-tooltip-container]'));

    //   tooltips.map((tooltip) => tooltip.addEventListener('mouseover', handleMouseOver));

    //   function handleMouseOver() {
    //     const tooltipbox = createTooltipBox(this);

    //     handleMouseMove.tooltipbox = tooltipbox;
    //     this.addEventListener('mousemove', handleMouseMove);

    //     handleMouseLeave.tooltipbox = tooltipbox;
    //     handleMouseLeave.element = this;
    //     this.addEventListener('mouseleave', handleMouseLeave);
    //   }

    //   const handleMouseLeave = {
    //     handleEvent() {
    //       this.tooltipbox.remove();
    //       this.element.removeEventListener('mousemove', handleMouseMove);
    //       this.element.removeEventListener('mouseleave', handleMouseLeave);
    //     },
    //   };

    //   const handleMouseMove = {
    //     handleEvent(e) {
    //       this.tooltipbox.style.top = `${e.clientY + 25}px`;
    //       this.tooltipbox.style.left = `${e.clientX + 13}px`;
    //     },
    //   };

    //   function createTooltipBox(el) {
    //     let tooltip = document.createElement('div');
    //     tooltip.innerText = el.getAttribute('data-tooltip-label');
    //     tooltip.classList.add('tooltip');

    //     document.body.appendChild(tooltip);

    //     return tooltip;
    //   }
    // }

    // initTooltip();

    onMounted(async () => {
      await store.dispatch('kabupatenModule/actionMapsKab');
      await store.dispatch('kabupatenModule/actionHargaMap');
      await tooltipOld();
    });

    // console.log(document.querySelector('g'));

    return { store, dataAll, isLoading, isModal, dataModal, modalData, changeValue };
  },
};
</script>

<style lang="scss" scoped>
:root {
  --x: 0.5;
  --y: 0.5;
}
.box-map {
  // position: relative;
  #getMap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .btn-maps {
      cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }
  }
  .description {
    pointer-events: none;
    position: absolute;
    font-size: 12px;
    text-align: center;
    background: #07162fdc;
    padding: 10px 15px;
    z-index: 5;
    left: 0;
    top: 0;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #eee;
    // transform: translate(calc(50% + 50vw * var(--x)), calc(50% + 30vh * var(--y)));
    display: none;
  }
  .description.active {
    display: block;
  }
  // .description:after {
  //   content: '';
  //   position: absolute;
  //   left: 50%;
  //   top: 100%;
  //   width: 0;
  //   height: 0;
  //   margin-left: -10px;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-top: 10px solid white;
  // }
}
</style>
