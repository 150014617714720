<template>
  <div class="flex fixed bottom-0 z-10 sm:text-base text-sm">
    <div class="bg-biruGelap text-white text-center harga">
      <span>Harga hari ini</span>
    </div>
    <div class="bg-primary bg-opacity-80 text-white">
      <marquee-text :duration="500" :paused="paused">
        <ul class="flex space-x-10">
          <li
            v-for="item in dataAll"
            @mouseenter="paused = !paused"
            @mouseleave="paused = false"
            :key="item.kabupaten_id"
          >
            <span>
              {{ jenisHarga !== 2 ? namaKomoditas : idKomoditas }} ( {{ item.kabupaten }} )
              {{ item.tanggal }} - {{ changeValue(item.harga) }}
            </span>
          </li>
        </ul>
      </marquee-text>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, computed, ref } from 'vue';
import MarqueeText from 'vue-marquee-text-component';

const store = useStore();
const paused = ref(false);
// eslint-disable-next-line no-unused-vars
const dataAll = computed(() => store.getters['kabupatenModule/getHarga']);
const idKomoditas = computed(() => store.getters['kabupatenModule/getKomoditas']);
const Komoditi = computed(() => store.getters['dataModule/getDataKomoditas']);
const jenisHarga = computed(() => store.state.kabupatenModule.jenisHarga);
// console.log(dataHistogram.value);
const koById = computed(() => Komoditi.value.find((item) => item.ko_id === idKomoditas.value));
const namaKomoditas = computed(() => koById.value?.ko_komoditi);
// console.log(komoditas.value);

const changeValue = (value) => {
  // console.log(value);
  let val;
  if (value) {
    // eslint-disable-next-line radix
    const num = parseInt(value);
    val = jenisHarga.value === 2 ? `${num} %` : value.replace('.', '').replace(',', '.');
  } else {
    val = jenisHarga.value === 2 ? (val = '0 %') : (val = 'Rp0');
  }

  return val;
};

onMounted(async () => {
  // await store.dispatch('kabupatenModule/actionMapsKab');
  // await store.dispatch('kabupatenModule/actionHargaMap');
});
</script>

<style lang="scss" scoped>
.harga {
  min-width: 20vw;
}

@media screen and (min-width: 1010px) {
  .harga {
    min-width: 10vw;
  }
}
</style>
