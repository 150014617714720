<template>
  <div>
    <div class="bg-biruGelap text-white text-sm sm:px-0 px-3 py-2">
      <div class="container mx-auto text-right">
        <ul class="inline-flex space-x-10">
          <li>
            <router-link to="/profile">Profil SiHati</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Kontak' }">Kontak Kami</router-link>
          </li>
          <li>
            <router-link to="/tentang">Tentang TPID</router-link>
          </li>
        </ul>
      </div>
    </div>
    <nav class="bg-primary text-white font-semibold py-2 sm:px-0 px-2">
      <div class="container flex flex-wrap items-center justify-between mx-auto py-2">
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          class="inline-flex items-center justify-center ml-3 text-gray-400 rounded-lg md:hidden hover:text-gray-900 focus:outline-none focus:ring-0"
          aria-controls="mobile-menu-2"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="hidden w-full mb-4 md:block md:w-auto md:mb-0" id="mobile-menu">
          <ul
            class="flex flex-col text-white mt-4 md:flex-row space-y-5 md:space-y-0 md:space-x-8 md:mt-0 text-sm"
          >
            <li>
              <router-link to="/">Beranda</router-link>
            </li>
            <!-- Dropdown menu -->
            <li>
              <button
                id="dropdownNavbarLink"
                data-dropdown-toggle="dropdownNavbar"
                class="flex items-center justify-between text-white"
              >
                <span class="font-semibold">Table Harga &dtrif;</span>
              </button>
              <div
                id="dropdownNavbar"
                class="z-10 hidden bg-primary divide-y divide-white-100 rounded"
              >
                <ul
                  class="px-7 py-4 w-full text-sm space-y-3"
                  aria-labelledby="dropdownLargeButton"
                >
                  <li>
                    <router-link to="/tabel-harga-komoditi">Komoditas</router-link>
                  </li>
                  <li>
                    <router-link to="/tabel-harga-kab">Kabupaten</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <router-link to="/inflasi-jateng">Inflasi Jawa Tengah</router-link>
            </li>
            <li>
              <router-link to="/inflasi-daerah">Inflasi Wilayah</router-link>
            </li>
            <li>
              <router-link to="/makro">Data Makroekonomi</router-link>
            </li>
            <li>
              <router-link to="/produksi">Data Produksi</router-link>
            </li>
          </ul>
        </div>
        <div class="flex space-x-2">
          <a
            href="https://play.google.com/store/apps/details?id=com.gitsolution.new_sihati"
            target="_blank"
          >
            <img src="@/assets/image/googleplay.svg" alt="google-play" />
          </a>
          <a href="https://apps.apple.com/id/app/sihati/id1634726974" target="_blank">
            <img src="@/assets/image/appstore.svg" alt="app-store" />
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>
