<template>
  <div class="grid grid-cols-12 text-white items-center">
    <Doughnut :value="value" />
    <div class="col-span-8 text-sm">
      <span class="block">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import Doughnut from '../Chart/DonutChart.vue';

export default {
  props: {
    title: String,
    value: String,
  },
  components: {
    Doughnut,
  },
  setup() {},
};
</script>
