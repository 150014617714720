<template>
  <!-- <div class="flex items-center justify-center">
    <button
      type="button"
      @click="openModal"
      class="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >
      Open dialog
    </button>
  </div> -->
  <TransitionRoot appear :show="isModalprop" as="template">
    <Dialog as="div" @close="$emit('closeBtn')" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="transform overflow-hidden bg-white text-left shadow-xl transition-all"
            >
              <DialogTitle class="text-gray-900 border-b">
                <div class="flex justify-between p-4">
                  <span class="font-bold">{{ datas.kabupaten }} - {{ komoditas }}</span>
                  <button
                    type="button"
                    @click="$emit('closeBtn')"
                    class="focus:ring-0 focus:outline-none focus:border-0"
                  >
                    x
                  </button>
                </div>
              </DialogTitle>
              <div class="loadingspinner" v-if="isLoading"></div>
              <div class="mt-2 py-1 px-5">
                <div class="overflow-x-auto">
                  <table class="min-w-full leading-normal rounded-md">
                    <thead class="bg-primary text-white rounded-t-md">
                      <tr>
                        <th class="py-2 px-4 font-semibold text-sm text-left">Kabupaten/Kota</th>
                        <th
                          v-for="item in dataMap.dates"
                          :key="item"
                          class="py-2 px-4 font-semibold text-sm text-left whitespace-nowrap"
                        >
                          {{ item.date }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="">
                        <td class="py-2 px-4 text-black font-semibold whitespace-nowrap">
                          {{ datas.kabupaten }}
                        </td>
                        <td
                          v-for="date in dataMap.harga"
                          :key="date"
                          class="py-2 px-4 text-black font-semibold"
                        >
                          {{ $filters.numId(date.harga).split(',')[0] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="mt-4 border-t">
                <div class="p-4 text-right">
                  <export-excel
                    class="inline-flex justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-white hover:bg-green-400 cursor-pointer"
                    :data="dataExcel"
                    worksheet="My Worksheet"
                    name="dataharga.xls"
                  >
                    Download Data
                  </export-excel>
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-400"
                    @click="$emit('closeBtn')"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
// import { ref } from 'vue';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import { useStore } from 'vuex';
import { computed, onUpdated, ref, watchEffect } from 'vue';

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  props: {
    isModalprop: Boolean,
    datas: Object,
  },
  setup(props) {
    const store = useStore();
    const dataExcel = ref([]);
    const dataMap = computed(() => store.getters['kabupatenModule/getMapDetail']);
    const komoditas = computed(() => store.getters['kabupatenModule/getKomoditas']);
    const isLoading = computed(() => store.state.kabupatenModule.isLoadingDetail);

    onUpdated(() => {
      const data = {
        Kabupaten: props.datas?.kabupaten,
      };
      watchEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        // eslint-disable-next-line array-callback-return, no-unused-expressions
        dataMap.value.dates?.map((date) => {
          const key = date.date;
          data[key] = dataMap.value.harga[date.date].harga;
        });
      });

      dataExcel.value = [];
      dataExcel.value.push(data);
    });
    // console.log(dataExcel.value);

    return { dataMap, isLoading, komoditas, dataExcel };
  },
};
</script>
