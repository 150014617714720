<template>
  <div>
    <div class="grid grid-cols-12 items-stretch">
      <div class="xl:col-span-8 col-span-12">
        <MapFilter />
        <Card>
          <div class="flex justify-between items-center text-white">
            <span>Peta Jawa Tengah</span>
            <span v-show="jenisHarga == 2">
              {{ jenisHarga !== 2 ? namaKomoditas : idKomoditas }}
            </span>
          </div>
          <div :class="section == 'maps' ? 'block' : 'hidden'" class="relative">
            <Maps />
            <span v-show="jenisHarga !== 2" class="text-white text-lg font-semibold">
              {{ jenisHarga !== 2 ? namaKomoditas : idKomoditas }}
            </span>
            <Rangecolor :jenisHarga="jenisHarga" />
          </div>
          <div :class="section == 'histogram' ? 'block' : 'hidden'" class="mt-10">
            <BarChart />
            <span v-show="jenisHarga !== 2" class="text-white text-lg font-semibold">
              {{ jenisHarga !== 2 ? namaKomoditas : idKomoditas }}
            </span>
            <Rangecolor :jenisHarga="jenisHarga" />
          </div>
        </Card>
      </div>
      <Card class="xl:col-span-4 col-span-12 xl:ml-5">
        <ListKabupaten />
        <div class="mt-10 flex justify-between items-center">
          <button
            :class="
              section == 'histogram'
                ? 'border border-blue-600 text-blue-600'
                : 'bg-blue-600 text-white '
            "
            class="py-1 px-2 rounded-md text-sm"
            @click="handleChange('maps')"
          >
            <i class="fa-solid fa-map" />
            <span class="ml-3">Tampilan Peta</span>
          </button>
          <button
            :class="
              section == 'maps' ? 'border border-blue-600 text-blue-600' : 'bg-blue-600 text-white '
            "
            class="py-1 px-2 rounded-md text-sm"
            @click="handleChange('histogram')"
          >
            <i class="fa-solid fa-chart-simple" />
            <span class="ml-3">Histogram</span>
          </button>
        </div>
      </Card>
    </div>
    <Perubahan />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import MapFilter from '@/components/MapFilter/index.vue';
import Perubahan from '@/components/Average/index.vue';
import Card from '@/components/Card/index.vue';
import Maps from '@/components/Maps/index.vue';
import ListKabupaten from '@/components/ListKabupaten/index.vue';
import Rangecolor from '@/components/Rangecolor/index.vue';
import BarChart from '@/components/Chart/BarChart.vue';

export default {
  components: {
    Card,
    Maps,
    Perubahan,
    ListKabupaten,
    Rangecolor,
    MapFilter,
    BarChart,
  },
  setup() {
    const store = useStore();
    const isMaps = ref('maps');
    const section = ref('maps');
    const Komoditi = computed(() => store.getters['dataModule/getDataKomoditas']);
    const idKomoditas = computed(() => store.getters['kabupatenModule/getKomoditas']);
    const dataAverage = computed(() => store.getters['kabupatenModule/getAvg']);
    const dataHistogram = computed(() => store.getters['kabupatenModule/getHistogram']);
    const category = computed(() => store.getters['kabupatenModule/getNameKab']);
    const jenisHarga = computed(() => store.state.kabupatenModule.jenisHarga);

    // filter komoditas
    const koById = computed(() => Komoditi.value.find((item) => item.ko_id === idKomoditas.value));
    const namaKomoditas = computed(() => koById.value?.ko_komoditi);

    const handleChange = (e) => {
      section.value = e;
    };

    onMounted(async () => {
      await store.dispatch('kabupatenModule/actionHargaMap');
    });

    return {
      handleChange,
      isMaps,
      namaKomoditas,
      section,
      dataAverage,
      category,
      jenisHarga,
      dataHistogram,
      idKomoditas,
    };
  },
};
</script>
