<template>
  <Bar :chart-options="chartOptions" ref="chart" :chart-data="chartData" :height="200" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  // eslint-disable-next-line vue/no-unused-components
  components: { Bar },
  setup() {
    const store = useStore();
    const chart = ref(null);
    const dataHistogram = computed(() => store.getters['kabupatenModule/getHistogram']);

    const chartData = computed(() => ({
      // labels: ['January', 'February', 'March'],
      labels: dataHistogram.value?.category,
      datasets: [
        {
          // label: 'Data',
          backgroundColor: dataHistogram.value?.colors,
          data: dataHistogram.value?.data,
          // data: [40, 20, 12],
        },
      ],
    }));

    const chartOptions = reactive({
      responsive: true,
      plugins: {
        legend: {
          // position: 'top',
          display: false,
        },
      },
    });

    return { chartData, chartOptions, chart, dataHistogram };
  },
};
</script>
