<template>
  <div>
    <header class="w-full text-center my-4">
      <h1 class="text-lg font-semibold text-white whitespace-nowrap">Data Makroekonomi</h1>
    </header>
    <div class="space-y-2 text-sm">
      <div
        v-for="item in dataMakro"
        :key="item"
        class="flex bg-primary rounded-md p-2 justify-between items-center text-white"
      >
        <p class="font-semibold">{{ item.home_title }}</p>
        <div :class="[colors(item.home_color), 'p-2 w-36 text-center rounded-r-md']">
          {{ item.home_value }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const dataMakro = computed(() => store.getters['makroekonomiModule/getMakroekonomi']);
    // console.log(dataMakro.value);
    function colors(color) {
      if (color === 'darkgreen') {
        return 'bg-green-600';
      }
      if (color === 'red') {
        return 'bg-red-600';
      }
      if (color === 'blue') {
        return 'bg-blue-600';
      }
      if (color === 'yellow') {
        return 'bg-yellow-600';
      }

      return '';
    }
    onMounted(async () => {
      // await store.dispatch('Makroekonomi/actionGetMakroekonomi');
    });

    return { dataMakro, colors };
  },
};
</script>
