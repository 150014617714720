/* eslint-disable func-names */
<template>
  <apexchart
    type="radialBar"
    height="120"
    :options="chartOptions"
    :series="[value]"
    class="chart col-span-4"
  />
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'radialBar',
        },
        fill: {
          colors: [
            // eslint-disable-next-line func-names
            function ({ value }) {
              if (value > 80) {
                return '#E9156E';
              }
              if (value >= 60 && value < 81) {
                return '#27AE60';
              }
              if (value >= 40 && value < 61) {
                return '#353DE1';
              }
              return '#C1C243';
            },
          ],
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '45%',
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: '12px',
                fontFamily: undefined,
                fontWeight: 400,
                color: 'white',
                offsetY: 5,
                formatter(value) {
                  return `${value}%`;
                },
              },
            },
          },
        },
        grid: {
          // show: true,
          padding: {
            top: -5,
            bottom: -5,
          },
        },
        stroke: {
          lineCap: 'round',
        },
        // labels: ['Cricket'],
      },
    };
  },
  setup() {},
};
</script>
