import axios from 'axios';

export default {
  namespaced: true,
  state: {
    makroekonomi: [],
  },
  getters: {
    getMakroekonomi: (state) => state.makroekonomi,
  },
  mutations: {
    SET_Makroekonomi(state, value) {
      state.makroekonomi = value;
      // state.contractor_maintenances.push(...value)
    },
  },
  actions: {
    async actionGetMakroekonomi({ commit }) {
      await axios
        .get('json/home')
        .then((response) => {
          // response.data.map((item) => console.log(item));
          // console.log(response.data.data_makro);
          commit('SET_Makroekonomi', response.data.data_makro);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(response.data);
    },
  },
};
