<template>
  <div class="relative text-white">
    <div class="grid grid-cols-12 bg-biruGelap py-2 px-3">
      <span class="col-span-8">Kabupaten/Kota</span>
      <span class="col-span-4">{{ jenisHarga === 2 ? 'Persen' : 'Harga' }}</span>
    </div>

    <div class="kab divide-y divide-solid divide-gray-100 overflow-auto scrollbar">
      <div v-if="isLoading" class="loadingspinner"></div>
      <div
        v-show="!isLoading"
        v-for="data in dataHargaKab"
        :key="data.kabupaten_id"
        class="grid grid-cols-12 py-2 px-3"
      >
        <span class="col-span-8">{{ data.ka_name }}</span>
        <span class="col-span-4">{{ changeValue(data?.harga) }}</span>
        <!-- <span>{{ data?.harga }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const dataHargaKab = computed(() => store.getters['kabupatenModule/getAll']);
    const isLoading = computed(() => store.state.kabupatenModule.isLoading);
    const jenisHarga = computed(() => store.state.kabupatenModule.jenisHarga);
    // console.log(dataHargaKab.value);

    // data?.harga
    //         ? data.harga.replace('.', '').replace(',', '.')
    //         : jenisHarga === 2
    //         ? '0 %'
    //         : 'Rp0'

    const changeValue = (value) => {
      // console.log(value);
      let val;
      if (value) {
        // eslint-disable-next-line eqeqeq
        if (value != 'Rp.0') {
          // eslint-disable-next-line radix
          const num = parseInt(value);
          val = jenisHarga.value === 2 ? `${num} %` : value.replace('.', '').replace(',', '.');
        } else {
          val = 'N/A';
        }
      } else {
        val = jenisHarga.value === 2 ? '0 %' : 'N/A';
        // val = jenisHarga.value === 2 ? (val = 'NaN') : (val = 'NaN');
      }

      return val;
    };

    return { dataHargaKab, isLoading, jenisHarga, changeValue };
  },
};
</script>

<style lang="scss" scoped>
.kab {
  max-height: 35rem;
  // position: relative;
  // z-index: 1;
}
</style>
