import { createRouter, createWebHistory } from 'vue-router';
import Layouts from '@/Layouts/index.vue';
import Beranda from '../views/Beranda.vue';
import TabelHargaKomoditas from '../views/TabelHargaKomoditas.vue';
import TabelHargaKabupaten from '../views/TabelHargaKabupaten.vue';
import InflasiJateng from '../views/InflasiJateng.vue';
import InflasiDaerah from '../views/InflasiDaerah.vue';
import DataProduksi from '../views/DataProduksi.vue';
import MakroEkonomi from '../views/MakroEkonomi.vue';
import Berita from '../views/Berita.vue';
import Kontak from '../views/Kontak.vue';
import Profile from '../views/Profile.vue';
import About from '../views/about.vue';

const routes = [
  {
    path: '/',
    component: Layouts,
    children: [
      {
        path: '',
        name: 'Beranda',
        component: Beranda,
      },
      {
        path: 'inflasi-jateng',
        name: 'InflasiKJateng',
        component: InflasiJateng,
      },
      {
        path: 'tabel-harga-komoditi',
        name: 'TabelHarga-Ko',
        component: TabelHargaKomoditas,
      },
      {
        path: 'tabel-harga-kab',
        name: 'TabelHarga-Kab',
        component: TabelHargaKabupaten,
      },
      {
        path: 'inflasi-daerah',
        name: 'InflasiKDaerah',
        component: InflasiDaerah,
      },
      // {
      //   path: 'inflasi-daerah/:id',
      //   name: 'InflasiKDaerah',
      //   component: InflasiDaerah,
      // },
      {
        path: 'produksi',
        name: 'Produksi',
        component: DataProduksi,
      },
      {
        path: 'makro',
        name: 'makro',
        component: MakroEkonomi,
      },
      {
        path: 'berita',
        name: 'Berita',
        component: Berita,
      },
      {
        path: 'kontak',
        name: 'Kontak',
        component: Kontak,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'tentang',
        name: 'Tentang',
        component: About,
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/About.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
