<template>
  <div class="w-full">
    <div class="mx-auto w-full rounded">
      <Disclosure v-slot="{ open }">
        <DisclosureButton
          :class="open ? 'rounded-t-md ' : 'rounded-md bg-opacity-80'"
          class="flex w-full justify-between rounded-t-md bg-primary px-4 py-2 text-left text-sm font-medium text-white"
        >
          <span>Tahun {{ data.history_year }}</span>
          <div class="text-lightBlue-400">
            <span class="font-normal text-sm mr-2">{{
              open ? 'Tutup detail inflasi' : 'Lihat detail inflasi'
            }}</span>
            <i :class="open ? '' : 'rotate-180 transform'" class="fa-solid fa-chevron-up" />
          </div>
        </DisclosureButton>
        <DisclosurePanel
          class="px-4 pt-4 divide-y divide-solid divide-gray-100 pb-2 rounded-b-md bg-primary bg-opacity-80 text-sm text-white"
        >
          <div
            class="grid grid-cols-12 gap-4 mb-3 py-3"
            v-for="item in data.history_result"
            :key="item"
          >
            <span class="col-span-6">{{ periode(item.period) }}</span>
            <ProgressBar :value="item.period_value" class="col-span-6" />
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import ProgressBar from '@/components/ProgressBar/index.vue';
import { ref } from 'vue';

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ProgressBar,
  },
  props: {
    data: Object,
  },
  setup() {
    const months = ref([
      {
        id: 1,
        name: 'January',
      },
      {
        id: 2,
        name: 'February',
      },
      {
        id: 3,
        name: 'Maret',
      },
      {
        id: 4,
        name: 'April',
      },
      {
        id: 5,
        name: 'Mei',
      },
      {
        id: 6,
        name: 'Juni',
      },
      {
        id: 7,
        name: 'Juli',
      },
      {
        id: 8,
        name: 'Agustus',
      },
      {
        id: 9,
        name: 'September',
      },
      {
        id: 10,
        name: 'Oktober',
      },
      {
        id: 11,
        name: 'November',
      },
      {
        id: 12,
        name: 'Desember',
      },
    ]);

    const periode = (value) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      months.value.filter((item) => item.id === +value.split(' ')[1]).map((i) => i.name)[0];

    return { months, periode };
  },
};
</script>
