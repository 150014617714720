<template>
  <Card class="grid sm:grid-cols-5 grid-cols-1 gap-5 items-end">
    <div>
      <label for="komoditas" class="block mb-2 text-sm text-white">Komoditas</label>
      <select
        v-model="state.komoditi"
        id="komoditas"
        class="bg-gray-50 py-1 text-gray-900 text-sm rounded-md focus:outline-none focus:ring-0 focus:border-0 block w-full"
      >
        <option v-for="item in komoditas" :key="item.ko_id" :value="item.ko_id">
          {{ item.ko_komoditi }}
        </option>
      </select>
    </div>
    <div>
      <label for="harga" class="block mb-2 text-sm text-white whitespace-nowrap"
        >Jenis Informasi Harga</label
      >
      <select
        v-model="state.jenisharga"
        id="harga"
        class="bg-gray-50 py-1 text-gray-900 text-sm rounded-md focus:ring-0 focus:border-0 block w-full"
      >
        <option v-for="item in jenisHarga" :key="item" :value="item.value">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div>
      <label for="date_filter" class="block mb-2 text-sm text-white">Tanggal</label>
      <input
        id="date_filter"
        type="date"
        :max="today"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full py-1"
        placeholder="Date"
        v-model="state.date"
      />
    </div>
    <div>
      <label for="perbandingan" class="block mb-2 text-sm text-white whitespace-nowrap"
        >Periode</label
      >
      <select
        :disabled="state.jenisharga == 1"
        v-model="state.periode"
        id="harga"
        class="bg-gray-50 py-1 text-gray-900 text-sm rounded-md focus:ring-0 focus:border-0 block w-full"
      >
        <option v-for="item in periode" :key="item" :value="item.value">
          {{ item.name }}
        </option>
      </select>
    </div>
    <button
      @click="handleFilter()"
      type="button"
      class="bg-blue-600 text-white rounded-md px-2 py-3"
    >
      Muat Filter
    </button>
  </Card>
</template>

<script setup>
import { useStore } from 'vuex';
import Card from '@/components/Card/index.vue';
import { computed, onMounted, reactive } from 'vue';

const store = useStore();
const date = new Date();
// const today = new Date(date.setDate(date.getDate() - 1)).toISOString().slice(0, 10);

// const picker = document.getElementById('date_filter');
// picker.addEventListener('input', function (e) {
// let day = new Date().getDay();
// console.log([6, 0].includes(day));
//   if ([6, 0].includes(day)) {
//     e.preventDefault();
//     this.value = '';
//     alert('Weekends not allowed');
//   }
// });

let today;
if (date.getDay() === 1) {
  today = new Date(date.setDate(date.getDate() - 2)).toISOString().slice(0, 10);
}
today = new Date(date.setDate(date.getDate() - 1)).toISOString().slice(0, 10);

const state = reactive({
  komoditi: '1',
  periode: 'dtd',
  date: today,
  jenisharga: 1,
});
const komoditas = computed(() => store.getters['dataModule/getDataKomoditas']);

const handleFilter = () => {
  store.dispatch('kabupatenModule/actionHargaMap', state);
};

const periode = [
  { name: 'Day to Day (DTD)', value: 'dtd' },
  { name: 'Week to Week (WTW)', value: 'wtw' },
  { name: 'Month to Month (MTM)', value: 'mtm' },
  // { name: 'Year on Year (YOY)', value: 'yoy' },
  // { name: 'Avg Year on Year (YOY)', value: 'ayoy' },
];
const jenisHarga = [
  { name: 'Perbandingan Harga', value: 1 },
  { name: 'Perubahan Harga', value: 2 },
];

onMounted(async () => {
  await store.dispatch('dataModule/actionGetKomoditas');
});
</script>
